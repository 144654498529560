










import Vue from 'vue'
import { ICompanyDetails, ICompanyAddress } from '@/types'
import { BaseFieldSelectCompany, BaseCard, BaseCardBody } from '@/components'
import { ManageAddresses } from './components'
import { assignInWith } from 'lodash'

export default Vue.extend({
    name: 'AdminManageDataAddresses',

    components: { BaseFieldSelectCompany, ManageAddresses, BaseCard, BaseCardBody },

    data: () => ({
        company: undefined as undefined | ICompanyDetails,
    }),
})
