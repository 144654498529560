































import Vue from 'vue'
import { faBuilding } from '@fortawesome/free-solid-svg-icons'
import { IStateDashboardCompanyAddresses, IStateAuth, IAddress } from '@/types'
import { ICompanyDetails, ICompanyAddress } from '@/types'
import { createNamespacedHelpers } from 'vuex'
import { BaseFieldGoogleAdressAutocomplete, BaseBtn } from '@/components'
import {
    ACTION_DASHBOARD_COMPANY_ADDRESSES,
    STORE_MODULE,
    MUTATION_DASHBOARD_COMPANY_ADDRESSES,
} from '@/enums'
import add from 'lodash/add'

interface ICompanyDetailsOptions {
    text: string
    value: ICompanyDetails
}

const { mapState: mapStateAuth } = createNamespacedHelpers(STORE_MODULE.AUTH)

const {
    mapState: mapStateCompanyAddresses,
    mapActions: mapActionsCompanyAddresses,
    mapMutations: mapMutations,
} = createNamespacedHelpers(STORE_MODULE.DASHBOARD_COMPANY_ADDRESSES)

export default Vue.extend({
    name: 'ManageAddresses',

    components: { BaseFieldGoogleAdressAutocomplete, BaseBtn },

    model: {
        prop: 'company',
        event: 'update:company',
    },

    props: {
        company: {
            type: Object as () => ICompanyDetails,
            required: true,
        },
        isValid: Boolean,
    },

    data: function () {
        return { faBuilding }
    },

    computed: {
        ...(mapStateAuth(['userAccount']) as MapStateToComputed<IStateAuth>),
        ...(mapStateCompanyAddresses(['companyAddresses']) as MapStateToComputed<
            IStateDashboardCompanyAddresses
        >),

        locations(): IAddress[] {
            return this.companyAddresses?.map(({ address }) => address) || []
        },
    },

    created() {
        this.fetchAndSetCompanyAddresses(this.company?.id)
    },

    methods: {
        addAddress(address: IAddress) {
            const companyAddress: ICompanyAddress = {
                companyId: this.company.id,
                hasJobs: false,
                address: address,
                id: undefined,
                headquarters: this.companyAddresses
                    ? this.companyAddresses?.length === 0
                    : true,
            }

            this.addCompanyAddress(companyAddress)
        },

        changeHeadquarters(index: number) {
            const updatedCompanyAddresses = this.companyAddresses?.map((companyAddress, i) => {
                return {
                    ...companyAddress,
                    address: {
                        ...companyAddress.address,
                    },
                    headquarters: index === i,
                }
            })

            this.changeCompanyAdressHeadquarters(updatedCompanyAddresses)
        },

        removeCompanyAddress(index: number) {
            if (this.companyAddresses) {
                let companyAddress = this.companyAddresses[index]

                if (companyAddress.hasJobs) {
                    alert("It's not possible to remove location if the are active jobs!")
                } else {
                    if (companyAddress.id) this.deleteCompanyAddress(companyAddress.id)
                    else {
                        this.companyAddresses = this.companyAddresses.splice(index, 1)
                    }
                }
            }
        },

        getAdressForDisplay(companyAddress: ICompanyAddress): String {
            return `${companyAddress.address.houseNumber} ${companyAddress.address.streetName},${companyAddress.address.postalCode} ${companyAddress.address.cityName},${companyAddress.address.countryName}`
        },

        ...mapActionsCompanyAddresses({
            fetchAndSetCompanyAddresses:
                ACTION_DASHBOARD_COMPANY_ADDRESSES.FETCH_AND_SET_COMPANY_ADDRESSES,
            deleteCompanyAddress: ACTION_DASHBOARD_COMPANY_ADDRESSES.DELETE_COMPANY_ADDRESS,
            addCompanyAddress: ACTION_DASHBOARD_COMPANY_ADDRESSES.ADD_COMPANY_ADDRESS,
            changeCompanyAdressHeadquarters:
                ACTION_DASHBOARD_COMPANY_ADDRESSES.CHANGE_HEADQUARTERS_COMPANY_ADDRESSES,
        }),
        ...mapMutations({
            setCompanyAddresses: MUTATION_DASHBOARD_COMPANY_ADDRESSES.SET_COMPANY_ADDRESSES,
        }),
    },
})
